import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Button } from "../components";

import CiaLogoImg from "../assets/img/home-cia-logo.svg";
import CowImg from "../assets/img/home-cow.svg";

export function Flip() {
  const telegramUrl = "https://t.co/mQyq2Ck8Mw";

  return (
    <div className="bg-[#FAFAF8] flex flex-col items-center sm:gap-[60px] gap-[20px] sm:py-[60px] py-[20px] sm:px-[32px] px-[20px]">
      <div className="flex flex-row gap-[32px] uppercase">
        <img
          src={CiaLogoImg}
          alt="cia-logo"
          className="sm:w-[100px] w-[75px]"
        />
        <p>
          <span className="text-[#5B4949] sm:text-[32px] text-[18px]">
            DECLASSIFIED PROJECT CODE: FLiP BIG MILK
            <span className="sm:block hidden">| BULLETIN #0002</span>
          </span>
          <span className="text-[26px] sm:block hidden">
            OBJECTIVE: awaken the minds of those who are yet to embrace the
            MOOOO
          </span>
        </p>
        <img src={CowImg} alt="cow" className="w-[100px] sm:block hidden" />
      </div>
      <img src={CowImg} alt="cow" className="w-[155px] block sm:hidden" />
      <a href={telegramUrl} target="_blank" rel="noopener noreferrer">
        <Button text="JOIN TELEGRAM FOR INSTRUCTIONS" />
      </a>
      <div className="flex flex-col gap-[26px] max-w-[1150px] uppercase sm:text-[20px] text-[12px]">
        <p>
          Raids in the MOOOOniverse are unlike anything done before in crypto.
        </p>
        <p>
          We do not target crypto people with the intent of shamelessly shilling
          our token. Instead, we leverage THIS NOVEL technology to spread
          awareness outside of the crypto world, gaining us powerful allies in
          the world of the dairy lobby.
        </p>
        <p>
          Our bovine master has carefully cultivated a short list of tweets from
          unsuspecting normies who comment on the state of cows, milk or beef.
          See telegram for the latest.
        </p>
        <p className="font-[900]">OUR MISSION</p>
        <p>
          Politely request they acknowledge the existence of the cow gif and
          that they will research further.
        </p>
        <p className="font-[900]">OUR ADVANTAGE</p>
        <p>
          Due to the powerful cognitive mesmerism of the cow gif, we never have
          to be explicit about purchasing tokens. We must only ensure they know
          we exist.
        </p>
        <p className="font-[900]">OUR RULES</p>
        <p>
          We have been granted one of the first non-military applications of
          this powerful technology, and with this responsibility we{" "}
          <span className="font-[900]">
            must remain thoughtful and respectful
          </span>{" "}
          in our raids.
        </p>
        <p>
          Now go forth, and awaken the minds of those who are yet to embrace the
          MOOOO. Together we will change the world.
        </p>
      </div>
      <a href={telegramUrl} target="_blank" rel="noopener noreferrer">
        <Button text="JOIN TELEGRAM FOR INSTRUCTIONS" />
      </a>
    </div>
  );
}
